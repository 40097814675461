export enum WebhookEventType {
  DeviceActionCreated = 'DEVICE_ACTION_CREATED',
  DeviceActionUpdated = 'DEVICE_ACTION_UPDATED',
  DeviceBulkDataStateUpdated = 'DEVICE_BULK_DATA_STATE_UPDATED',
  DeviceNotificationReported = 'DEVICE_NOTIFICATION_REPORTED',
  DeviceStateUpdated = 'DEVICE_STATE_UPDATED',
}

export const getWebhookEventTypeValue = (value: string): WebhookEventType | undefined => {
  return Object.values(WebhookEventType).find((enumValue) => enumValue === value);
};

export const getWebhookEventTypeValues = (values: string[]): WebhookEventType[] => {
  return values.map((value) => getWebhookEventTypeValue(value)).filter((value) => value) as WebhookEventType[];
};
